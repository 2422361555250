.main {
    height: 100%;
    justify-content: center;
    align-items: center;
}

.login-container p {
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 4px;
    margin-top: 0px;
}

.login-container ion-item {
    margin-bottom: 20px;
}

.login-container {
    background-color: white;
    padding: 40px;
    width: 33%;
    min-width: 418px;
}

.login-container ion-button {
    --padding-end: 48px;
    --padding-start: 48px;
}