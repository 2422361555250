.irca-table,
.irca-table-mobile {
    width: 100%;
}

.irca-table {
    overflow-x: auto;
}

.ion-irca-table-nodata {
    min-height: 250px;
    flex: 1 1 auto;
}

table {
    width: 100%;
    font-family: proxima-nova, sans-serif;
}

.irca-table thead tr {
    height: 53px;
    text-align: left;
    text-transform: uppercase;
}

.irca-table tbody tr {
    height: 76px;
    text-align: left;
}

.irca-table tbody tr.clickable {
    cursor: pointer;
}

.irca-table tbody tr.clickable:hover {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
}

.irca-table tbody tr.clickable:hover ion-chip {
    --color: var(--ion-color-white) !important;
    --background: rgba(var(--ion-color-white-rgb), 0.08) !important;
}

.irca-table tbody tr:nth-child(even) {
    background-color: var(--ion-color-white);
}

.irca-table tbody tr:nth-child(odd) {
    background-color: var(--ion-color-light);
}

.irca-table.condensed tbody tr td,
.irca-table.condensed thead tr th {
    padding: 8px 8px;
}

.irca-table tbody tr td,
.irca-table thead tr th {
    padding: 25px 14px;
}

.irca-table thead tr th ion-icon {
    margin-left: 5px;
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.irca-table-mobile ion-accordion::part(content) {
    background: var(--ion-color-white);
}

.irca-table-mobile ion-grid ion-row:not(:last-child) {
    border-bottom: 1px solid var(--ion-color-light);
}

.irca-table-mobile ion-label ion-row {
    flex-wrap: nowrap;
}

.scroll-buttons {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
}

.page-buttons {
    padding: 16px 0;
}

.page-buttons ion-button {
    margin-right: 20px;
    --padding-start: 16px;
    --padding-end: 16px;
}

.irca-table .input-table ion-input {
    --padding-start: 8px;
    --padding-end: 8px;
}