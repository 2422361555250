.input-table ion-icon {
    font-size: 26px;
    background: transparent;
}

.input-table {
    display: flex;
}

.input-table button {
    background: transparent;
    z-index: 99;
    margin-right: 5px;
    display: flex;
    align-items: center;
}

.input-table input::-webkit-outer-spin-button,
.input-table input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-table {
    background-color: var(--ion-color-white) !important;
    border: 1px solid var(--ion-color-light);
    border-radius: 10px;
}