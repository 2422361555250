/* TODO: fix highlight  */
/* ion-datetime::part(native) .calendar-day-highlight {
    width: 100% !important;
    border-radius: 0 !important;
} */


ion-datetime {
    --background: var(--ion-color-white);
    min-width: 100%;
}