/* ION ITEM INPUTS START */
/* ion-item:has(ion-select),
ion-item:has(ion-input),
ion-item:has(ion-textarea),
ion-item:has(.date-time-label) {
  --background: white;
  --border-color: var(--ion-color-light);
  --border-width: 1px;
} */

.item-input,
.item-select {
  --background: var(--ion-color-white);
  --border-color: var(--ion-color-light);
  --border-width: 1px;
}

ion-item:has(.date-picker-icon):hover {
  cursor: pointer;
}

ion-item:has(ion-select)::part(native),
ion-item:has(ion-textarea)::part(native) {
  border-radius: 10px;
}

/* ION ITEM > CHECKBOX START */
ion-item:has(ion-checkbox)::part(native) {
  --inner-border-width: 0px;
}

/* ION SEGMENTS START */
ion-segment-button.segment-button-checked {
  color: white;
}

ion-segment-button.segment-button-checked::part(indicator-background) {
  background-color: var(--ion-color-primary);
}

/* ION SEGMENTS END */

/* ION INPUT START */

ion-input>input {
  font-family: proxima-nova, sans-serif !important;
}