@font-face {
    font-family: ff-enzo-web;
    src: url(../assets/fonts/386FC6_0_0.eot);
    src: url(../assets/fonts/386FC6_0_0.eot?#iefix) format('embedded-opentype'), url(../assets/fonts/386FC6_0_0.woff2) format('woff2'), url(../assets/fonts/386FC6_0_0.woff) format('woff'), url(../assets/fonts/386FC6_0_0.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: ff-enzo-web;
    src: url(../assets/fonts/386FC6_1_0.eot);
    src: url(../assets/fonts/386FC6_1_0.eot?#iefix) format('embedded-opentype'), url(../assets/fonts/386FC6_1_0.woff2) format('woff2'), url(../assets/fonts/386FC6_1_0.woff) format('woff'), url(../assets/fonts/386FC6_1_0.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: ff-enzo-web;
    src: url(../assets/fonts/386FC6_4_0.eot);
    src: url(../assets/fonts/386FC6_4_0.eot?#iefix) format('embedded-opentype'), url(../assets/fonts/386FC6_4_0.woff2) format('woff2'), url(../assets/fonts/386FC6_4_0.woff) format('woff'), url(../assets/fonts/386FC6_4_0.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: ff-enzo-web;
    src: url(../assets/fonts/386FC6_5_0.eot);
    src: url(../assets/fonts/386FC6_5_0.eot?#iefix) format('embedded-opentype'), url(../assets/fonts/386FC6_5_0.woff2) format('woff2'), url(../assets/fonts/386FC6_5_0.woff) format('woff'), url(../assets/fonts/386FC6_5_0.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}