h1 {
  /* HEADER 1/Header 1 */
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 40px !important;
  font-weight: 500 !important;
  line-height: 50px !important;
  letter-spacing: 0.25px !important;
  text-transform: uppercase !important;
}

h2 {
  /* HEADER 4/Regular */
  margin-top: 0px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 27px !important;
  line-height: 35px !important;
  text-transform: uppercase !important;
}

h3 {
  /* HEADER 6/Regular */
  margin-top: 0px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  letter-spacing: 0.15px !important;
  text-transform: uppercase !important;
}

h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  letter-spacing: 0.1px !important;
}

* {
  /* Body/Body 2 */
  margin-top: 0px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.25px;
}