.step-tab #divisor {
  width: 100%;
  height: 1px;
  margin-top: 14px;
  margin-bottom: 10px;
  background-color: var(--ion-color-medium);
}

.step-tab #divisor.step-tab-selected {
  width: 100%;
  height: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: var(--ion-color-primary);
}