ion-button::part(native) {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
}

ion-button.small-circle-icon {
  width: 36px;
  height: 36px;
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
}

ion-button.small-circle-icon ion-icon {
  font-size: 16px;
}

ion-button.medium-circle-icon {
  width: 56px;
  height: 56px;
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
}

ion-button.medium-circle-icon ion-icon {
  font-size: 26px;
}