.tabs-tables ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    border-bottom: 1px solid var(--ion-color-medium);
    overflow-x: auto;
}

.tabs-tables li[aria-selected=true] {
    border-bottom: 3px solid var(--ion-color-primary);
}

.tabs-tables li[aria-selected=true]>ion-button {
    --color: var(--ion-color-primary);
}

.tabs-tables li[aria-selected=false]>ion-button {
    --color: var(--ion-color-medium);
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
    #detail-customer-modal {
        --width: 80%;
        --height: 80%;
    }
}

.tabs-tables div.react-tabs__tab-panel--selected {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 39px);
}