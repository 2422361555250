.full-flex-column {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.flex-fill {
    flex: 1 1 auto;
}

ion-split-pane {
    margin-top: 76px;
}