.navigation-footer ion-button {
    --padding-end: 80px;
    --padding-start: 80px;
}

.navigation-footer ion-row {
    justify-content: space-between;
}

.navigation-footer ion-toolbar {
    --padding-top: 20px;
    --padding-bottom: 20px;
    --background: white;
    min-height: 82px;
    display: flex;
}

.navigation-footer ion-buttons ion-button:first-child {
    margin-right: 20px;
}

@media only screen and (max-width: 600px) {
    .navigation-footer ion-button {
        --padding-end: 25px;
        --padding-start: 25px;
    }

    .navigation-footer ion-toolbar {
        --padding-top: 10px;
        --padding-bottom: 10px;
    }

    .navigation-footer ion-row {
        flex-direction: column;
        align-items: center;
    }

    .navigation-footer .children-slot {
        width: 100%;
    }

    .navigation-footer ion-buttons {
        width: 100%;
        justify-content: space-between;
    }

    .navigation-footer ion-buttons ion-button:first-child {
        margin-right: 0;
    }

    .children-slot>* {
        margin-bottom: 10px;
    }
}