ion-menu {
  --background: white;
  --max-width: 317px;
}

ion-menu * {
  --background: transparent;
  background-color: transparent !important;
}

ion-menu ion-list {
  padding: 0px;
}

ion-menu ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
}

ion-menu ion-item {
  --border-radius: 4px;
}

ion-menu ion-item.selected {
  /* --background: rgba(var(--ion-color-primary-rgb), 0.14); */
  --background: none;
}

ion-menu ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-menu ion-label {
  font-family: proxima-nova, sans-serif !important;
}

ion-accordion-group>ion-accordion:last-of-type ion-item[slot=header] {
  --border-width: 0 0 1px 0 !important;
}