.main {
    height: 100%;
    justify-content: center;
    align-items: center;
}

.company-container h1 {
    margin-bottom: 20px;
}

.company-container {
    background-color: white;
    padding: 40px;
    width: 33%;
    min-width: 418px;
}