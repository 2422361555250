.menu-card {
    width: 100%;
    background-color: var(--ion-color-white);
    border-radius: 20px;
    overflow: hidden
}

.menu-card.clickable {
    cursor: pointer;
}

.menu-card .header {
    height: 43px;
}

.menu-card .header ion-icon {
    margin-left: 20px;
}

.menu-card .header .header-text {
    text-transform: uppercase;
    margin-left: 15px;
}

.menu-card .header .header-text h6 {
    margin: 0;
}